// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/resource-section/ResourceSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/resource-section/ResourceSection.tsx");
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import "./resourcesection.css?__remix_sideEffect__";
import { createPublicUrl } from "~/utils/helper";
export default function ResourceSection({
  blogs
}) {
  return <section className="resources_section">
      <div className="container">
        <h3 className="subheading">Resources</h3>
        <h2 className="heading">
          Discover how to market your business on Allinone like an expert.
        </h2>
        <div className="row gy-4 justify-content-center">
          {blogs && blogs.map((blog, index) => index < 3 ? <div className="col-12 col-lg-4" key={index}>
                  <div className="resource_card">
                    <div className="head">
                      <div className="imgbox">
                        <img src={createPublicUrl(blog.image) ? createPublicUrl(blog.image) : "/image/no-image.svg"} alt="image" />

                      </div>
                    </div>

                    <div className="body">
                      <h2 className="title">{blog.title}</h2>
                      <p className="pera">{blog.description}</p>

                      <Link to="/coming-soon" className="btn">
                        Learn more
                        <div className="icon_box">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">

                            <path d="M9.32812 3C9.51562 3 9.67708 3.0625 9.8125 3.1875L14.4688 7.85938C14.6042 7.98438 14.6719 8.14062 14.6719 8.32812C14.6719 8.51562 14.6042 8.67708 14.4688 8.8125L9.8125 13.4688C9.67708 13.6042 9.51562 13.6719 9.32812 13.6719C9.14062 13.6719 8.98438 13.6042 8.85938 13.4688C8.73438 13.3438 8.67188 13.1875 8.67188 13C8.67188 12.8125 8.73438 12.6562 8.85938 12.5312L12.3906 9H2C1.8125 9 1.65625 8.93229 1.53125 8.79688C1.39583 8.67188 1.32812 8.51562 1.32812 8.32812C1.32812 8.15104 1.39583 7.99479 1.53125 7.85938C1.65625 7.73438 1.8125 7.67188 2 7.67188H12.3906L8.85938 4.14062C8.73438 4.01562 8.67188 3.85938 8.67188 3.67188C8.67188 3.47396 8.73438 3.3125 8.85938 3.1875C8.98438 3.0625 9.14062 3 9.32812 3Z" fill="#00838F" />

                          </svg>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div> : null)}
        </div>
      </div>
    </section>;
}
_c = ResourceSection;
var _c;
$RefreshReg$(_c, "ResourceSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;